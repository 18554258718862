<template>
  
    <div class="h-40 bg-filters rounded-lg mt-2 flex flex-col p-2 shadow-md">

        <div class="h-4 flex flex-row justify-start items-center">
            <i :class="iconByNotificationType(data.notification_type)"></i>
            <span class="ml-auto text-3xs text-font-gray">{{ data.date |  moment('DD / MM / YYYY')}}</span>
        </div>

        <div class="h-auto max-h-8 mt-2">
            <span class="font-semibold text-xs truncate-2" :class="getColor(data.notification_type)">{{ data.title }}</span>
        </div>

        <div class="flex-1 min-h-0 mt-1 overflow-hidden">
            <span class="text-xxs text-font-gray" v-html="data.body"></span>
        </div>

        <div class="h-4 mt-2 flex flex-row justify-end items-center">
            <i v-if="data.link !== ''" class="mdi mdi-open-in-new text-font-gray cursor-pointer text-sm mr-2" @click="goLink(data.link)"></i>
            <i v-if="data.file !== ''" class="mdi text-s cursor-pointer" :class="extension(data.file)" @click="openFile(data.file, 'file')"></i>
            <span class="text-white text-xxs ml-2 mr-1">{{ data.likes.length }}</span>
            <i class="mdi mdi-thumb-up-outline text-font-gray text-xxs" @click="dolike(data.id)" :class="meLike(data.likes)"></i>
        </div>

    </div>

</template>

<script>
export default {
    props:['data'],
    data(){
        return{

        }
    },
    methods:{
        iconByNotificationType (notification) {
            return this.$iconByNotification(notification)
        },
        goLink(link) {
            this.$goLink(link) 
        },
        extension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        openFile(file, type) {
            this.$openFile(file, type) 
        },
        dolike(id) {
            this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=Alert&id='+id)
                .then(response => {
                    this.load();
                })
        },
        meLike(alerts) {
            return this.$meLike(alerts)
        },
        getColor(notification){
            return this.$colorByNotificationType(notification)
        }
    },
    computed:{

    }
}
</script>

<style>

</style>