<template>
    <div class="h-auto w-full flex flex-col">
        <loader :loading="loading" />
        <div class="h-auto p-2">
            <div v-if="alerts" class="h-auto w-full rounded-lg module p-2 flex flex-col">
                <div class="h-auto py-1">
                    <span class="text-danger text-xs font-semibold">Alerts</span>
                </div>
                <div class="h-6 rounded-lg bg-body my-2">
                    <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-6 rounded text-xs bg-body
                        flex flex-row justify-start items-center px-2">
                </div>
                <div class="h-auto py-1 flex flex-row">
                    <div @click="filterByAlert('mild')" class="cursor-pointer h-full w-1/3 flex flex-row justify-center items-center" :class="{ 'opacity-zeus-30': selectAlert.length!==0&&selectAlert !== 'mild' }">
                        {{totals.mild}} <i class="text-font-gray mdi mdi-alert-circle-outline relative">
                        <span class="h-2 w-2 absolute top-0 right-0 rounded-full bg-success"></span>
                        </i>
                    </div>
                    <div @click="filterByAlert('medium')" class="cursor-pointer h-full w-1/3 flex flex-row justify-center items-center" :class="{ 'opacity-zeus-30': selectAlert.length!==0&&selectAlert !== 'medium' }">
                        {{totals.medium}}  <i class="text-font-gray mdi mdi-reload-alert relative">
                        <div class="h-2 w-2 absolute top-0 right-0 rounded-full bg-warning"></div>
                        </i>
                    </div>
                    <div @click="filterByAlert('serious')" class="cursor-pointer h-full w-1/3 flex flex-row justify-center items-center"  :class="{ 'opacity-zeus-30': selectAlert.length!==0&&selectAlert !== 'serious' }">
                        {{totals.serious}} <i class="text-font-gray mdi mdi-alert relative">
                        <div class="h-2 w-2 absolute top-0 right-0 rounded-full bg-danger"></div>
                        </i>
                    </div>
                </div>

                <alert-post v-for="(el,index) in search(filteredAlerts)" :key="index" :data="el"/>

            </div>

        </div>
    </div>
</template>

<script>
import alertPost from '../../components/Mobile/personalDashboard/AlertPost.vue';
import {state} from '@/store';

export default {
    components:{
        alertPost
    },
    data() {
        return {
            alerts: [],
            searchInput: '',
            selectAlert: [],
            loading: false,
            totals: {
                serious: 0,
                medium: 0,
                mild: 0
            }
        }
    },
    methods: {
        dolike(id) {
            this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=Alert&id='+id)
                .then(response => {
                    this.load();
                })
        },
        meLike(alerts) {
            return this.$meLike(alerts)
        },
        iconByNotificationType (notification) {
            return this.$iconByNotification(notification)
        },
        search(data) {
            return data.filter(post => {
                return post['title'].toLowerCase().
                includes(this.searchInput.toLowerCase()) || post['body'].toLowerCase().
                includes(this.searchInput.toLowerCase())
            })
        },
        countByType(type) {
            if (type === 'serious') return this.totals.serious
            else if (type === 'medium') return this.totals.medium
            else return this.totals.mild
        },
        filterByAlert(alert) {
            if (alert === this.selectAlert) this.selectAlert = []
            else this.selectAlert = alert
        },
        load() {
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Alert&sorts[date]=DESC&filters[active]=1', {params: state.globalParams}).then(response => {
                this.alerts = response.data
                const { serious, medium, mild   }  = this.$alertsCount(this.alerts)
                this.totals.serious = serious
                this.totals.medium = medium
                this.totals.mild = mild
                this.loading = false
            })
        },
        extension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        openFile(file, type) {
            this.$openFile(file, type) 
        },
        goLink(link) {
            this.$goLink(link) 
        },
        isValid(link) {
            this.$is_url(link) 
        }
    },
    computed: {
        filteredAlerts() {
            if (this.selectAlert.length === 0) { return this.alerts }
            return this.alerts.filter(({ notification_type }) => {
                return this.selectAlert.includes(notification_type);
            });
        }
    },
    mounted(){
        this.load();
    }
}
</script>